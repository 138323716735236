import React from "react";
import './Navbar.css'
import Toggle from "../Toggle/Toggle";
import {Link} from 'react-scroll';
import Logo from '../../img/logo.jpg';

const Navbar = () => {
    return (
        <div className="n-wrapper" id="Navbar">
            <div className="n-left">
                <a href="." className="logo">
                    <img src={Logo} alt="MOS Design Studio"/>
                </a>
                {/* <div className="n-name">MOS Design Studio</div> */}
                {/* <Toggle/> */}
            </div>
            <div className="n-right">
                <div className="n-list">
                    <ul style={{listStyleType: "none"}}>
                        <Link spy={true} to='Navbar' smooth={true} activeClass="activeClass">
                            <li>Home</li>
                        </Link>
                        <Link spy={true} to='Service' smooth={true}>
                            <li>Services</li>
                        </Link>
                        <Link spy={true} to='Experience' smooth={true}>
                            <li>Experience</li>
                        </Link>
                        <Link spy={true} to='Projects' smooth={true}>
                            <li>Projects</li>
                        </Link>
                        <Link spy={true} to='Testimonials' smooth={true}>
                            <li>Testimonials</li>
                        </Link>
                        <Link spy={true} to='Contact' smooth={true} className="button">
                            <li>Contact Us</li>
                        </Link>
                    </ul>                    
                </div>
                {/* <button className="button n-button">Contact</button> */}
            </div>
        </div>
    )
}

export default Navbar