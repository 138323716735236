import React from "react";
import './Footer.css';
import Wave from '../../img/wave.png';
import Insta from '@iconscout/react-unicons/icons/uil-instagram';

const Footer = () => {
    return (
        <div className="footer">
            <img src={Wave} alt="Wave" style={{width: '100%'}}/>
            <div className="f-content">
                <span>architect@mosdesignstudio.in</span>
                <div className="f-icons">
                    <a href="https://www.instagram.com/mos_mrinmayi?igsh=MW9waW84enFvemhuZg==">
                        <Insta color='white' size='3rem'/>
                    </a>
                    {/* <Facebook color='white' size='3rem'/>
                    <LinkedIn color='white' size='3rem'/> */}
                </div>
            </div>
        </div>
    )
}

export default Footer