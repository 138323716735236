import React from "react";
import './Intro.css';
import Facebook from '../../img/Facebook.png';
import Instagram from '../../img/instagram.png';
import LinkedIn from '../../img/linkedin.png';
import Vector1 from '../../img/Vector1.png';
import Vector2 from '../../img/Vector2.png';
import Banner from '../../img/banner.png';
import Thumbup from '../../img/thumbup.png';
import Crown from '../../img/crown.png';
import GlassesImoji from '../../img/glassesimoji.png';
import FLoatingDiv from "../FLoatingDiv/FloatingDiv";
import { themeContext } from "../../context";
import { useContext } from "react";

const Intro = () => {

    const transition = {duration: 2, type: 'spring'};
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div className="intro">
            <div className="i-left">
                <div className="i-name">
                    <span style={{color: darkMode? 'white':''}}>Welcome to</span>
                    <span>MOS Design Studio</span>
                    <span>At MOS Design Studio, we are committed to creating unique and beautiful spaces that reflect our clients’ personalities and lifestyles. Our team of interior designers and architects offers a comprehensive range of services, from concept development to project management, to ensure a seamless and personalized experience for every client.</span>
                </div>
                <div className="i-icons">
                    {/* <img src={LinkedIn} alt="LinkedIn" />
                    <img src={Facebook} alt="Facebook" /> */}
                    <a href="https://www.instagram.com/mos_mrinmayi?igsh=MW9waW84enFvemhuZg==">
                        <img src={Instagram} alt="Instagram" />
                    </a>
                </div>
            </div>
            <div className="i-right">
                <img src={Vector1} alt="Vector1" />
                <img src={Vector2} alt="Vector2" />
                {/* <img src={Banner} alt="" /> */}
                <img 
                src={GlassesImoji} alt="" />
                <div style={{top: '-4%', left: '52%'}} className="floating-div">
                    <FLoatingDiv image={Crown} txt1='Beautiful' txt2='Homes'/>
                </div>
                <div style={{top: '18rem', left: '0rem'}} className="floating-div">
                    <FLoatingDiv image={Thumbup} txt1='Pocket-friendly' txt2="Homes"/>
                </div>
                {/* blur divs */}
                <div className='blur' style={{background: "rgb(238 210 255)"}}></div>
                <div className="blur" style={{background: '#C1F5FF', top: '17rem', width: '21rem', height: '11rem', left: '-9rem'}}></div>
            </div>
        </div>
    )
}

export default Intro