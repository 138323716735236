import React from "react";
import './FloatingDiv.css';

const FLoatingDiv = ({image, txt1, txt2}) => {
    return (
        <div className="floatingdiv">
            <img src={image} alt={txt1 + " " + txt2} />
            <span>
                {txt1}
                <br/>
                {txt2}
            </span>
        </div>
    )
}

export default FLoatingDiv