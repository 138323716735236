import React from "react";
import './Portfolio.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import pImage1 from '../../img/1.jpeg';
import pImage2 from '../../img/2.jpeg';
import pImage3 from '../../img/3.jpeg';
import pImage4 from '../../img/4.jpeg';
import pImage5 from '../../img/5.jpeg';
import pImage6 from '../../img/6.jpeg';
import pImage7 from '../../img/7.jpeg';
import pImage8 from '../../img/8.jpeg';
import pImage9 from '../../img/9.jpeg';
import pImage10 from '../../img/10.jpeg';
import pImage11 from '../../img/11.jpeg';
import pImage12 from '../../img/12.jpeg';
import pImage13 from '../../img/13.jpg';
import pImage14 from '../../img/14.jpg';
import pImage15 from '../../img/15.jpg';
import pImage16 from '../../img/16.jpg';
import HOC from '../../img/hoc.png';
import MusicApp from '../../img/musicapp.png';
import {Pagination} from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css';

const Portfolio = () => {
    const hasWindow = typeof window !== 'undefined'; 
    const width = hasWindow ? window.screen.width : null;
    const slidesPerView = width > '480' ? 3 : 1;
    const portfolios = [
        {img: pImage1},{img:pImage2},{img:pImage3},{img:pImage4},{img:pImage5},{img:pImage9},
        {img: pImage13},{img: pImage14},{img: pImage15},{img: pImage16}
    ]
    return (
        <div className="portfolio" id="Projects">
            {/* heading */}
            <span>Recent Projects</span>
            <span>Portfolio</span>

            {/* Slider */}
            <Swiper
            mpdules={[Pagination]}
            slidesPerView={slidesPerView}
            pagination={{clickable: true}}
            grabCursor={true}
            className="portfolio-slider"
            >
                {portfolios.map((portfolio, index)=>{
                    return (
                        <SwiperSlide key={index}>
                            <img src={portfolio.img} alt={index} />                     
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default Portfolio