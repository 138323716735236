import React from "react";
import './Services.css';
import HeartEmoji from '../../img/heartemoji.png';
import Glasses from '../../img/glasses.png';
import Humble from '../../img/humble.png';
import Card from "../Card/Card";

const Services = () => {
    return (
        <div className="services" id="Services">
            {/* left side */}
            <div className="awesome">
                <span>Our</span>
                <span>Services</span>
                <span>
                    We specialize in interior design, architecture,  
                    <br/>
                    and cutting-edge 3D rendering and transform spaces  
                    <br/>
                    into captivating, functional environments. 
                    <br/>
                    From residential to corporate projects, our collaborative  
                    <br/>
                    approach ensures personalized, innovative designs that
                    <br/>
                    bring visions to life. Experience the perfect blend 
                    <br/>
                    of creativity and precision with us.
                </span>
                <div className="blur s-blur1" style={{background: "#ABF1FF94"}}></div>
            </div>
            {/* right side */}
            <div className="cards">
                <div style={{top: '-10rem', left: '34rem'}}> 
                    <Card
                    emoji={HeartEmoji}
                    heading='Interior Design'
                    detail='Our team works closely with clients to understand their vision and translate it into a unique and personalized design. We offer a comprehensive range of interior design services, from concept development to project management, to ensure a seamless and stress-free experience for our clients.'
                    />
                </div>
                {/* second card */}
                <div style={{top: '12rem', left: '10rem'}}>
                <Card
                    emoji={Glasses}
                    heading='Architecture'
                    detail='We offer a full range of architectural services that encompass every stage of the design and construction process. From initial concept development to final construction drawings, we work closely with our clients to ensure that every detail is accounted for and that the final product meets their expectations.'
                    />
                </div>
                {/* third card */}
                <div style={{top: '19rem', left: '32rem'}}>
                <Card
                    emoji={Humble}
                    heading='3D Rendering'
                    detail='Our 3D rendering services allow clients to visualize their designs in stunning detail and clarity. We use the latest software and technology to create realistic and accurate renderings that help clients make informed decisions about their designs.'
                    />
                </div>
                <div className="blur s-blur2" style={{background: 'var(--purple'}}></div>
            </div>
        </div>
    )
}

export default Services