import React from "react";
import './Testimonials.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css';

const Testimonials = () => {
    const clients = [
        {
            name: "Shivani Deshmukh",
            review: "MOS Design Studio exceeded my expectations! Their attention to detail and commitment to creating a personalized design made my experience stress-free and enjoyable. I highly recommend them to anyone looking for a unique and beautiful interior design."
        },
        {
            name: "Keshav Singh",
            review: "The team at MOS Design Studio is truly talented! Their expertise in architecture and interior design made my project come to life, and I couldn’t be happier with the final result. I highly recommend them to anyone looking for a professional and personalized design experience."
        },
        {
            name: "Vaibhavi Deo",
            review: "MOS Design Studio is the best in the business! Their 3D rendering services allowed me to visualize my project in incredible detail, and their attention to detail and commitment to quality made my experience truly exceptional. I highly recommend them to anyone looking for a beautiful and unique interior design."
        }

    ]
    return (
        <div className="t-wrapper" id="testimonials">
            <div className="t-heading">
                <span>Clients always get </span>
                <span> Exceptional Work </span>
                <span>from us...</span>
                <div className="blur t-blur1" style={{background: 'var(--purple'}}></div>
                <div className="blur t-blur2" style={{background: 'skyblue'}}></div>
            </div>
            <Swiper
            mpdules={[Pagination]}
            slidesPerView={1}
            pagination={{clickable: true}}
            >
                {clients.map((client, index)=>{
                    return (
                        <SwiperSlide key={index}>
                            <div className="testimonial">                               
                                <span>{client.review}</span>
                                <span>- {client.name}</span>
                            </div>                            
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default Testimonials